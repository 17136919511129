import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import { openChatWidget } from '~/utils/zendesk'
import type {
  CancelPanelProps,
  CancelPanelOptions,
} from '~/components/CancelFlowPanels/CancelFlowPanel.types'
import GenericCancelPanel from '../../GenericCancelPanel/GenericCancelPanel.ui'
const PanelCSChat: React.FC<CancelPanelProps> = ({
  handleModalClose,
  panel,
}) => {
  const ModalOptions: Array<CancelPanelOptions> = React.useMemo(
    () => [
      {
        cypressId: TEST_ID.CANCEL_FLOW_SAVE,
        targetSaveComponent: 'Open ZenDesk Chat Widget',
        text: 'Contact customer service',
        clickHandler: () => {
          handleModalClose()
          openChatWidget()
        },
      },
    ],
    [handleModalClose]
  )

  return <GenericCancelPanel modalOptions={ModalOptions} panel={panel} />
}

export default PanelCSChat
